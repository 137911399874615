import React, { useState, useEffect, useCallback } from 'react';
import {
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ERROR_MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../../common/constants/AppConstants';
import { apiRequest, handleError } from '../../../services/Service';
import { GET_APPROVALS, UPDATE_APPROVAL } from '../../../services/ApiUrls';
import { useDispatch, useSelector } from 'react-redux';
import NominationRequestListItem from '../components/NominationRequestListItem';
import {
  saveProgressLoadingState,
  saveShowEmptyContentView,
  selectShowEmptyContentView,
} from '../../../state/UIState';
import { useHistory } from 'react-router';
import LoadMoreBtn from '../../../common/ui/load_more_btn/LoadMoreBtn';
import DefaultContentView from '../../../common/ui/default_content_view/DefaultContentView';
import { logEvent } from '../../../utils/FirebaseAnalyticsUtils';
import ActivityLoader from '../../../common/ui/activity_loader/ActivityLoader';
import { selectCheersNominationRequestTabData } from '../../../state/TabData';
import { Modal } from 'react-bootstrap';

const NominationsRequestsTab = ({ tabKey }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [cheersList, setCheersList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadMoreFlag, setLoadMoreFlag] = useState(false);
  const [pageId, setPageId] = useState(1);
  const nominationRequestTabData = useSelector(selectCheersNominationRequestTabData);
  const showEmptyContentView = useSelector(selectShowEmptyContentView);
  const [flag, setFlag] = useState(false);
  const [method, setMethod] = useState();

  useEffect(() => {
    logEvent(ANALYTICS_EVENT_TYPES.NOMINATIONS_REQUEST_TAB_VISIT, '', ANALYTICS_ITEM_NAMES.CHEERS);
  }, []);

  const callRequestsApi = useCallback(
    async (id, loadMore) => {
      dispatch(saveShowEmptyContentView(true));
      if (loadMore) {
        const params = {
          page_id: id,
          type: tabKey,
        };
        try {
          setLoading(true);
          const apiResponse = await apiRequest(GET_APPROVALS, REQUEST_TYPE.GET, params);
          setLoading(false);
          if (id == 1) {
            setCheersList([]);
          }
          if (Object.keys(apiResponse).length > 0) {
            if (apiResponse.response.status === true) {
              if (apiResponse.response.data.feeds.length > 0) {
                setCheersList((oldArray) => [...oldArray, ...apiResponse.response.data.feeds]);
                setPageId((prev) => prev + 1);
                setLoadMoreFlag(true);
              } else {
                setLoadMoreFlag(false);
              }
            }
          }
        } catch (err) {
          handleError(err, params, GET_APPROVALS, NAVIGATION_ROUTES.CHEERS_SCREEN);
          setLoading(false);
        }
      }
    },
    [dispatch, tabKey]
  );

  useEffect(() => {
    if (tabKey === nominationRequestTabData) {
      callRequestsApi(1, true);
    }
  }, [callRequestsApi, nominationRequestTabData, tabKey]);

  const callApproveRejectApi = async (status, item) => {
    const params = new URLSearchParams();
    params.append('approved', status);
    params.append('cheer_employee_award_id', item.cheer_employee_award_id);
    params.append('points', item.points);
    params.append('extra_points', item.extra_points);
    try {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: true,
          progressMessage: 'Uploading...',
        })
      );
      const apiResponse = await apiRequest(UPDATE_APPROVAL, REQUEST_TYPE.POST, params);
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          setPageId(1);
          setLoadMoreFlag(true);
          setCheersList([]);
          callRequestsApi(1, true);
        }
      }
    } catch (err) {
      handleError(err, params, UPDATE_APPROVAL, NAVIGATION_ROUTES.CHEERS_SCREEN);
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  };

  const onPressReject = async (item) => {
    await callApproveRejectApi(false, item);
    setMethod('reject');
    setFlag(true);
  };

  const onPressApprove = async (item) => {
    await callApproveRejectApi(true, item);
    setMethod('approve');
    setFlag(true);
  };
  const SuccessConfirmationModal = () => (
    <Modal
      show={flag}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size="sm"
      onHide={() => setFlag(false)}>
      <Modal.Body>
        <div className="michildcare-text-centre">
          <div className="michildcare-modal-title">Submitted successfully!</div>
          {/* <div>{`Are you sure?`} </div> */}
          <div>
            {`The nomination has been  ${
              method === 'approve' ? 'approved' : 'rejected'
            } successfully.`}{' '}
          </div>
        </div>
        <div className="michildcare-confirmation-modal-btn-container">
          <div className="ml-3" />
          <button
            onClick={() => {
              setFlag(false);
            }}
            className="michildcare-modal-buttons">
            OK
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
  const onPressOthers = (nominationsList) => {
    history.push(NAVIGATION_ROUTES.TAGGED_PEOPLE_SCREEN, {
      nominationsList: nominationsList,
      from: 'rewardCentral',
      title: 'Nominated by',
    });
  };

  const onPressLoadMore = () => {
    callRequestsApi(pageId, loadMoreFlag);
  };

  const emptyListView = () => {
    return <DefaultContentView message={ERROR_MESSAGES.SEARCH_NO_RESULTS} />;
  };

  return (
    <div className="main-content-container">
      {SuccessConfirmationModal()}
      {showEmptyContentView && !cheersList.length && !loading ? (
        emptyListView()
      ) : (
        <div>
          {cheersList.map((item, index) => {
            return (
              <NominationRequestListItem
                key={`${index}`}
                item={item}
                onPressApprove={onPressApprove}
                onPressReject={onPressReject}
                onPressOthers={onPressOthers}
              />
            );
          })}
          <ActivityLoader visible={loading} />
          {loadMoreFlag && <LoadMoreBtn onClick={onPressLoadMore} isLoading={loading} />}
        </div>
      )}
    </div>
  );
};

export default React.memo(NominationsRequestsTab);
