import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getSecuredPdfUrl, getUrlWithSpecificRendition } from '../../../utils/common';
import { getFeatureComponentUrl } from '../../../utils/accessibility';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ERROR_MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
  SCREEN_DENSITY_QUALIFIERS,
} from '../../../common/constants/AppConstants';
import { useHistory } from 'react-router';
import { PANDEMIC_SUPPORT_GUIDE } from '../../../services/ApiUrls';
import { apiRequest, handleError } from '../../../services/Service';
import { selectConfigurations, selectWebBaseUrl } from '../../../state/MasterData';
import { logEvent } from '../../../utils/FirebaseAnalyticsUtils';
import { selectShowEmptyContentView } from '../../../state/UIState';
import CustomButton from '../../../common/ui/custom_button/CustomButton';
import DefaultContentView from '../../../common/ui/default_content_view/DefaultContentView';
import ActivityLoader from '../../../common/ui/activity_loader/ActivityLoader';
import { VscChromeClose } from 'react-icons/vsc';
import ReactPlayer from 'react-player';
import { Modal } from 'react-bootstrap';

const GuideTab = () => {
  const history = useHistory();
  const accessibilityData = useSelector(selectAccessibilityData);
  const configurations = useSelector(selectConfigurations);
  const [guides, setGuides] = useState([]);
  const showEmptyContentView = useSelector(selectShowEmptyContentView);
  const [loading, setLoading] = useState(true);
  const webUrl = useSelector(selectWebBaseUrl);
  const [showEmptyView, setShowEmptyView] = useState(false);
  const [showVideoItem, setShowVideoItem] = useState(false);
  const [article, setArticle] = useState();
  const handleVideoItemClose = () => setShowVideoItem(false);
  const handleVideoItemShow = () => setShowVideoItem(true);

  const callGuidesAPi = useCallback(async () => {
    try {
      setShowEmptyView(false);
      setLoading(true);
      const apiResponse = await apiRequest(PANDEMIC_SUPPORT_GUIDE, REQUEST_TYPE.GET, {});
      setLoading(false);
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          if (apiResponse.response.data.overviews) {
            const data = apiResponse.response.data.overviews;
            if (data.length > 0) {
              setGuides(data);
            } else {
              setShowEmptyView(true);
            }
          }
        }
      } else {
        setShowEmptyView(true);
      }
    } catch (err) {
      setShowEmptyView(true);
      handleError(err, {}, PANDEMIC_SUPPORT_GUIDE, NAVIGATION_ROUTES.PANDEMIC_SUPPORT);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    callGuidesAPi();
  }, [callGuidesAPi]);

  const onPressBannerHandler = (youtubeId) => {
    if (youtubeId) {
      history.push(`${NAVIGATION_ROUTES.FULL_SCREEN_VIDEO}/${youtubeId}`);
    }
  };

  const onPressAskOrg = () => {
    window.open(configurations._helpdesk_url, '_blank');
  };

  useEffect(() => {
    logEvent(
      ANALYTICS_EVENT_TYPES.PANDEMIC_SUPPORT_GUIDE_TAB_VISIT,
      '',
      ANALYTICS_ITEM_NAMES.PANDEMIC_SUPPORT
    );
  }, []);

  const renderItem = (item) => {
    return (
      <div
        className="pandemic-support-background-img-container"
        onClick={(e) => {
          e.stopPropagation();
          console.log('item', item);
          setArticle(item);
          //onPressBannerHandler(item.youtube_url);
          handleVideoItemShow();
        }}>
        <img
          src={getUrlWithSpecificRendition(
            item.promo_image.base_url,
            SCREEN_DENSITY_QUALIFIERS.HDPI,
            item.promo_image.image_path
          )}
          className="pandemic-support-image common-cursor-pointer"
        />
        {!!item.youtube_url && (
          <div className="pandemic-support-foreground-container">
            <img
              src={getFeatureComponentUrl(
                accessibilityData,
                ACCESSIBILITY_FEATURE_COMPONENTS.NEW_JOINEES_VIDEO_PLAY_BUTTON,
                ACCESSIBILITY_IDENTIFIERS.NEW_JOINEES
              )}
              className="pandemic-support-video-button-image"
            />
          </div>
        )}
        <div className="pandemic-support-article-title pandemic-support-guide-title-container">
          {item.title}
        </div>
        {item.encoded_url?.map((pdfObject, index) => {
          return (
            <div key={index}>
              <a
                href={getSecuredPdfUrl(webUrl, pdfObject.pdf_url)}
                target="_blank"
                rel="noreferrer"
                onClick={(e) => {
                  e.stopPropagation();
                }}>
                <div className="pandemic-support-guide-pdf-container">
                  <img
                    src={getFeatureComponentUrl(
                      accessibilityData,
                      ACCESSIBILITY_FEATURE_COMPONENTS.NEW_JOINEES_PDF_ATTACHMENT,
                      ACCESSIBILITY_IDENTIFIERS.NEW_JOINEES
                    )}
                    className="pandemic-support-guide-pdf-icon"
                  />
                  <div className="pandemic-support-guide-pdf-title">{pdfObject.label}</div>
                </div>
              </a>
            </div>
          );
        })}
      </div>
    );
  };

  const emptyListView = () => {
    return <DefaultContentView message={ERROR_MESSAGES.PANDEMIC_SUPPORT_NO_GUIDES} />;
  };

  return (
    <div>
      <div className="d-flex flex-row-reverse mi-hr-activity-btn-container">
        <Modal
          show={showVideoItem}
          onHide={() => setShowVideoItem(false)}
          className="home-fullscreen-modal-dialog fade-scale"
          aria-labelledby="example-custom-modal-styling-title">
          <Modal.Body className="animate-bottom">
            <div
              className="home-fullscreen-modal-dialog-close-button-container"
              onClick={handleVideoItemClose}>
              <VscChromeClose size="4em" color="#fff" />
            </div>

            <ReactPlayer
              url={`https://www.youtube.com/watch?v=${article?.youtube_url}`}
              width="100%"
              height="100%"
              playing={true}
              controls={true}
              className="home-video-container"
            />
          </Modal.Body>
        </Modal>
        <CustomButton buttonStyle="pandemic-support-bottom-button" onClick={onPressAskOrg}>
          Ask Microland
        </CustomButton>
      </div>
      <ActivityLoader visible={loading} />
      {showEmptyView && !guides.length ? (
        emptyListView()
      ) : (
        <div className="row">
          {guides &&
            guides.map((item, index) => {
              return (
                <div key={`${index}`} className="col-sm-4 mb-3">
                  <div>{renderItem(item)}</div>
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
};

export default GuideTab;
