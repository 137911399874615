import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
  SCREEN_TITLE,
  WISHES_TITLES_SUBTITLES,
  WISH_TYPES,
} from '../../common/constants/AppConstants';
import { selectAccessibilityData } from '../../state/AccessibilityData';
import { getFeatureComponentUrl, getHeaderComponentUrl } from '../../utils/accessibility';
import {
  formatDateDDMonth,
  formatDateDDMonthYYYY,
  getAwardIcon,
  getCurrentYear,
  getDOB,
  showToast,
} from '../../utils/common';
import Divider from '../../common/ui/divider/Divider';
import WishCard from '../../common/ui/profile_detail/components/WishCard';
import UserProfilePhoto from '../../common/ui/user_profile_photo/UserProfilePhoto';
import { GET_CHEERS, GET_EMPLOYEE_BY_ID } from '../../services/ApiUrls';
import { apiRequest, handleError } from '../../services/Service';
import { selectEmployeeData } from '../../state/EmployeeData';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import ProfileContactView from './components/ProfileContactView';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { saveProgressLoadingState } from '../../state/UIState';
import { selectScheduleMeetTitleConfig } from '../../state/MasterData';
import { logEvent3 } from '../../utils/FirebaseAnalyticsUtils';
import { resetAllTabs } from '../../state/TabData';

const EmployeeProfileDetailScreen = ({ props }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { employeeId } = useParams();
  const accessibilityData = useSelector(selectAccessibilityData);
  const scheduleMeetDefaultTitle = useSelector(selectScheduleMeetTitleConfig);
  const [isUserOwnProfile, setIsUserOwnProfile] = useState(false);
  const [isEmployeeDisabled] = useState(false);
  const [profileData, setProfileData] = useState([]);
  const [myVaccinationStatus, setMyVaccinationStatus] = useState('');
  const employeeData = useSelector(selectEmployeeData);
  const [showMore, setShowMore] = useState(false);
  const [readMore, setReadMore] = useState(false);
  const [cheersData, setCheersData] = useState({});

  let fromEmployeeId = employeeData?.employee_id ?? '';
  let toEmployeeId = profileData?.employee_id ?? '';

  const deiBackgroundStyle = {
    backgroundImage: `url(${getFeatureComponentUrl(
      accessibilityData,
      ACCESSIBILITY_FEATURE_COMPONENTS.DEI_CHAMP_CARD,
      ACCESSIBILITY_IDENTIFIERS.PROFILE
    )})`,
    backgroundRepeat: 'no-repeat',
    height: '210px',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    borderRadius: '10px',
    padding: '20px',
    position: 'relative',
  };

  useEffect(() => {
    if (employeeId == employeeData.employee_id) {
      history.replace(NAVIGATION_ROUTES.MY_PROFILE_DETAIL + '?tab=profile');
    }
  }, [employeeId, employeeData]);

  const callEmployeeDetailApi = useCallback(async () => {
    dispatch(saveProgressLoadingState({ isProgressLoading: true }));
    try {
      const apiResponse = await apiRequest(GET_EMPLOYEE_BY_ID + employeeId, REQUEST_TYPE.GET, {});
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      if (Object.keys(apiResponse).length) {
        if (apiResponse.response.status) {
          if (Object.keys(apiResponse.response.data.employee).length) {
            setProfileData(apiResponse.response.data.employee);
            setMyVaccinationStatus(apiResponse.response.data.employee?.vaccination_status);
          }
        }
      }
    } catch (err) {
      handleError(
        err,
        {},
        GET_EMPLOYEE_BY_ID + employeeId,
        NAVIGATION_ROUTES.EMPLOYEE_PROFILE_DETAIL
      );
      dispatch(saveProgressLoadingState({ isProgressLoading: true }));
    }
  }, [dispatch, employeeId]);

  const renderScreen = useCallback(async () => {
    callEmployeeDetailApi();
  }, [callEmployeeDetailApi]);

  useEffect(() => {
    setProfileData([]);
    if (employeeId != employeeData.employee_id) {
      if (employeeId) {
        renderScreen();
      } else {
        setProfileData(employeeData);
        setMyVaccinationStatus(employeeData.vaccination_status);
        setIsUserOwnProfile(true);
      }
    }
  }, [employeeData, employeeId, props, renderScreen]);

  const emailRedirect = () => {
    logEvent3(ANALYTICS_EVENT_TYPES.PROFILE_EMAIL, fromEmployeeId, toEmployeeId);
    if (profileData.email) {
      window.open(
        `mailto:${profileData.email}?subject=Message from ${
          employeeData.first_name + ' ' + employeeData.last_name
        }`
      );
    }
  };

  const scheduleMeetRedirect = () => {
    logEvent3(ANALYTICS_EVENT_TYPES.PROFILE_MEET, fromEmployeeId, toEmployeeId);
    let meetingUrl = encodeURI(
      `https://outlook.office.com/owa/?path=/calendar/action/compose&rru=addevent&subject=${scheduleMeetDefaultTitle}&to=${profileData.email}`
    );
    if (profileData.email) {
      window.open(meetingUrl);
    }
  };

  const websiteRedirect = (websiteUrl) => {
    window.open(websiteUrl, '_blank').focus();
  };

  const formatDob = (dob) => {
    if (dob) {
      return formatDateDDMonth(dob, false);
    }
  };

  const callCheersApi = useCallback(async () => {
    try {
      dispatch(saveProgressLoadingState({ isProgressLoading: true }));
      const apiResponse = await apiRequest(
        GET_CHEERS + `/${profileData.employee_id}/latest`,
        REQUEST_TYPE.GET,
        {}
      );
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status) {
          if (apiResponse.response.data) {
            setCheersData(apiResponse?.response?.data ?? {});
          }
        }
      }
    } catch (err) {
      handleError(
        err,
        {},
        GET_CHEERS + `/${profileData.employee_id}/latest`,
        NAVIGATION_ROUTES.EMPLOYEE_PROFILE_DETAIL
      );
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      // error
    }
  }, [dispatch, profileData]);

  useEffect(() => {
    if (profileData && Object.keys(profileData) && Object.keys(profileData).length > 0) {
      callCheersApi();
    }
  }, [profileData, callCheersApi]);

  const onEmployeeClick = (e, employeeId) => {
    e.stopPropagation();
    if (employeeId) {
      if (employeeId === employeeData.employee_id) {
        history.push(NAVIGATION_ROUTES.MY_PROFILE_DETAIL);
      } else {
        history.push(`${NAVIGATION_ROUTES.EMPLOYEE_PROFILE_DETAIL}/${employeeId}`);
      }
    } else if (props.profileData && Object.keys(props.profileData).length) {
      if (props.profileData.employee_id === employeeData.employee_id) {
        history.push(NAVIGATION_ROUTES.MY_PROFILE_DETAIL);
      } else {
        history.push(
          `${NAVIGATION_ROUTES.EMPLOYEE_PROFILE_DETAIL}/${props.profileData.employee_id}`
        );
      }
    }
  };

  return (
    <div className="container mb-3">
      {!isUserOwnProfile && <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.PROFILE} />}
      {!!Object.keys(profileData).length && (
        <div>
          <div className="common-profile-name-image-container justify-content-between row mt-3">
            <div className="profile-container-top">
              <div>
                <UserProfilePhoto
                  imageBaseUrl={profileData.profile_picture.base_url}
                  imagePath={profileData.profile_picture.image_path}
                  imageClass="common-user-profile-photo-xl"
                  showBadge={true}
                  profileData={profileData}
                  fromRoute={NAVIGATION_ROUTES.MY_PROFILE_DETAIL}
                />
                {!!profileData.pronouns && (
                  <p className="my-profile-pronoun-text mb-0 ml-1">
                    {profileData.pronouns.slice(0, 12)}
                  </p>
                )}
              </div>
              <div className="user-details-container-profile">
                <div className="user-details-andchampion-container">
                  <div className="d-flex align-items-center">
                    <p className="my-profile-detail-title">
                      {profileData.first_name +
                        `${profileData.middle_name ? ' ' + profileData.middle_name + ' ' : ' '}` +
                        profileData.last_name}
                    </p>
                  </div>
                  <p className="my-profile-normal-text">{profileData.position_title}</p>
                  <p className="my-profile-normal-text">{profileData.department_name}</p>
                  {(profileData?.dei_champion || profileData?.esg_champion) && (
                    <div className="dei-esg-champion-container">
                      {!!profileData?.esg_champion && (
                        <div className="champion-container">
                          <img
                            src={getFeatureComponentUrl(
                              accessibilityData,
                              ACCESSIBILITY_FEATURE_COMPONENTS.PROFILE_DEI_STAR,
                              ACCESSIBILITY_IDENTIFIERS.PROFILE
                            )}
                            className="esg-dei-champion-star mr-1"
                          />
                          <p className="champion-text esg-champion">ESG CHAMPION</p>
                        </div>
                      )}
                      {!!profileData?.dei_champion && (
                        <div className="champion-container">
                          <img
                            src={getFeatureComponentUrl(
                              accessibilityData,
                              ACCESSIBILITY_FEATURE_COMPONENTS.PROFILE_ESG_STAR,
                              ACCESSIBILITY_IDENTIFIERS.PROFILE
                            )}
                            className="esg-dei-champion-star mr-1"
                          />
                          <p className="champion-text dei-champion">DEI CHAMPION</p>
                        </div>
                      )}
                    </div>
                  )}
                </div>
                {isUserOwnProfile && (
                  <div
                    onClick={() => {
                      history.push(NAVIGATION_ROUTES.MY_PROFILE_DETAIL_EDIT);
                    }}
                    className="common-cursor-pointer">
                    <img
                      src={getFeatureComponentUrl(
                        accessibilityData,
                        ACCESSIBILITY_FEATURE_COMPONENTS.PROFILE_EDIT,
                        ACCESSIBILITY_IDENTIFIERS.PROFILE
                      )}
                      className="my-profile-edit-icon mr-3"
                    />
                  </div>
                )}
                {!isUserOwnProfile && profileData.enabled == 1 && (
                  <div className="col-6 col-sm-6 col-lg-6">
                    <div className="d-flex justify-content-end">
                      <ProfileContactView
                        emailRedirect={emailRedirect}
                        scheduleMeetRedirect={scheduleMeetRedirect}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <Divider style="common-profile-divider-style mt-3" />
          {!!profileData.description && (
            <div className="col-12 col-sm-12 p-0">
              <div>
                <p className="common-profile-title-text mb-2 mt-3">
                  About{' '}
                  {isUserOwnProfile ? 'Me' : profileData.first_name + ' ' + profileData.last_name}
                </p>
                <p
                  className={`common-profile-subtitle-text mb-3 ${
                    profileData.description?.length > 350 && !readMore && 'max-two-lines-profile'
                  }`}>
                  {profileData.description ? profileData.description : '⠀'}
                </p>
                {profileData.description?.length > 350 && (
                  <div
                    className="common-profile-show-more-text common-cursor-pointer"
                    onClick={() => setReadMore(!readMore)}>
                    {readMore ? 'Read Less' : 'Read More'}
                  </div>
                )}
              </div>
              <Divider style="common-profile-divider-style" />
            </div>
          )}
          {!!profileData.employee_code && (
            <div className="col-12 col-sm-12 p-0">
              <div>
                <p className="common-profile-title-text mb-2 mt-3">Employee ID</p>
                <div className="common-profile-row-container mb-3">
                  <p className="common-profile-subtitle-text mb-0">{`${profileData.employee_code}${
                    profileData.enabled === 0 ? ' (Ex-Microlander)' : ''
                  }`}</p>
                </div>
              </div>
              <Divider style="common-profile-divider-style" />
            </div>
          )}
          {profileData.email && (
            <div className="col-12 col-sm-12 p-0">
              <div>
                <p className="common-profile-title-text mb-2 mt-3">Email</p>
                <div className="common-profile-row-container mb-3">
                  <p className="common-profile-subtitle-text mb-0">{profileData.email}</p>
                  {!isUserOwnProfile && (
                    <CopyToClipboard
                      text={profileData.email}
                      onCopy={() => showToast(MESSAGES.COPIED_TO_CLIPBOARD)}>
                      <img
                        src={getFeatureComponentUrl(
                          accessibilityData,
                          ACCESSIBILITY_FEATURE_COMPONENTS.PROFILE_COPY,
                          ACCESSIBILITY_IDENTIFIERS.PROFILE
                        )}
                        className="common-profile-visible-icon common-cursor-pointer mr-3"
                      />
                    </CopyToClipboard>
                  )}
                </div>
              </div>
              <Divider style="common-profile-divider-style" />
            </div>
          )}
          {(profileData.mobile_number.is_mobile_number_visible || isUserOwnProfile) &&
            profileData?.mobile_number?.number?.length > 0 && (
              <div className="col-12 col-sm-12 p-0">
                <div>
                  <p className="common-profile-title-text mb-2 mt-3">Phone</p>
                  <div className="common-profile-row-container mb-3">
                    <p className="common-profile-subtitle-text mb-0">
                      {(profileData.mobile_number.isd_code ? '+' : '') +
                        profileData.mobile_number.isd_code +
                        ' ' +
                        profileData.mobile_number.number.slice(0, 5) +
                        ' ' +
                        profileData.mobile_number.number.slice(5, 10)}
                    </p>
                    {isUserOwnProfile && (
                      <img
                        src={getFeatureComponentUrl(
                          accessibilityData,
                          profileData.mobile_number.is_mobile_number_visible
                            ? ACCESSIBILITY_FEATURE_COMPONENTS.PROFILE_VISIBLE
                            : ACCESSIBILITY_FEATURE_COMPONENTS.PROFILE_HIDE,
                          ACCESSIBILITY_IDENTIFIERS.PROFILE
                        )}
                        className="common-profile-visible-icon mr-3"
                      />
                    )}
                  </div>
                </div>
                <Divider style="common-profile-divider-style" />
              </div>
            )}
          {isUserOwnProfile && profileData.work_phone && !!profileData.work_phone.number && (
            <div className="col-12 col-sm-12 p-0">
              <div>
                <p className="common-profile-title-text mb-2 mt-3">Business Phone</p>
                <div className="d-flex justify-content-between">
                  <p className="common-profile-subtitle-text mb-3">
                    {'+'}
                    {profileData.work_phone.isd_code +
                      ' ' +
                      profileData.work_phone.number.slice(0, 5) +
                      ' ' +
                      profileData.work_phone.number.slice(5, 10)}
                  </p>
                  {!!profileData.work_phone.extension && (
                    <p className="common-profile-subtitle-text">
                      Ext:
                      {profileData.work_phone.extension}
                    </p>
                  )}
                </div>
              </div>
              <Divider style="common-profile-divider-style" />
            </div>
          )}
          {showMore && (
            <div>
              {!!profileData?.location?.title && (
                <div className="col-12 col-sm-12 p-0">
                  <div>
                    <p className="common-profile-title-text mb-2 mt-3">Location</p>
                    <p className="common-profile-subtitle-text mb-3">
                      {profileData?.location?.title}
                    </p>
                  </div>
                  <Divider style="common-profile-divider-style" />
                </div>
              )}
              {!!profileData?.department_name_2 && (
                <div className="col-12 col-sm-12 p-0">
                  <div>
                    <p className="common-profile-title-text mb-2 mt-3">Department 2</p>
                    <p className="common-profile-subtitle-text mb-3">
                      {profileData?.department_name_2}
                    </p>
                  </div>
                  <Divider style="common-profile-divider-style" />
                </div>
              )}
              {!!profileData.last_working_date && (
                <div className="col-12 col-sm-12 p-0">
                  <div>
                    <p className="common-profile-title-text mb-2 mt-3">Last Working Day</p>
                    <p className="common-profile-subtitle-text mb-1">
                      {formatDateDDMonthYYYY(profileData.last_working_date, false)}
                    </p>
                  </div>
                  <Divider style="common-profile-divider-style" />
                </div>
              )}
              {!!profileData.date_of_joining && (
                <div className="col-12 col-sm-12 p-0">
                  <div>
                    <p className="common-profile-title-text mb-2 mt-3">Date of Joining</p>
                    <p className="common-profile-subtitle-text mb-1">
                      {formatDateDDMonthYYYY(profileData.date_of_joining, false)}
                    </p>
                    {isUserOwnProfile && profileData.joining_date_percentile && (
                      <p className="common-profile-percentile-text mb-3">
                        {Math.round(profileData.joining_date_percentile)}% microlanders joined after
                        you!
                      </p>
                    )}
                  </div>
                  <Divider style="common-profile-divider-style" />
                </div>
              )}
              {!!profileData.supervisor_name &&
                !!profileData.reporting_manager &&
                !isEmployeeDisabled && (
                  <div className="col-12 col-sm-12 p-0">
                    <div>
                      <p className="common-profile-title-text mb-2 mt-3">
                        {profileData?.career_manager_id?.toString() ===
                        profileData?.reporting_manager?.employee_id?.toString()
                          ? 'Reporting & Career Manager'
                          : 'Reporting Manager'}
                      </p>
                      <div className="common-profile-reporting-manager-container d-flex flex-row align-items-center justify-content-between mb-3 pl-3 pr-3">
                        <div className="d-flex flex-row align-items-center">
                          <UserProfilePhoto
                            imageBaseUrl={profileData.reporting_manager.profile_picture.base_url}
                            imagePath={profileData.reporting_manager.profile_picture.image_path}
                            imageClass="common-user-profile-photo-sm"
                            showBadge={true}
                            employeeId={profileData.reporting_manager.employee_id}
                          />
                          <div
                            className="ml-3 mt-3"
                            onClick={() => {
                              history.push(NAVIGATION_ROUTES.ORG_STRUCTURE, {
                                empCode: profileData.employee_code,
                              });
                            }}>
                            <div className="d-flex align-items-center">
                              <p className="common-profile-supervisor-text mb-0 ">
                                {profileData.supervisor_name}
                              </p>
                              {!!profileData.reporting_manager.pronouns && (
                                <p className="common-profile-supervisor-pronounce-text mb-0 ml-1">
                                  {'(' + profileData.reporting_manager.pronouns + ')'}
                                </p>
                              )}
                            </div>
                            <p className="common-profile-supervisor-position-text">
                              {profileData.reporting_manager.position_title}
                            </p>
                          </div>
                        </div>
                        <div
                          onClick={() => {
                            history.push(NAVIGATION_ROUTES.ORG_STRUCTURE, {
                              empCode: profileData.employee_code,
                            });
                          }}
                          className="common-cursor-pointer">
                          <img
                            src={getFeatureComponentUrl(
                              accessibilityData,
                              ACCESSIBILITY_FEATURE_COMPONENTS.PROFILE_VIEW_HIERARCHY,
                              ACCESSIBILITY_IDENTIFIERS.PROFILE
                            )}
                            className="common-profile-profile-hierarchy-size"
                          />
                        </div>
                      </div>
                    </div>
                    <Divider style="common-profile-divider-style" />
                  </div>
                )}
              {!!profileData.career_manager &&
                profileData?.career_manager_id?.toString() !==
                  profileData?.reporting_manager?.employee_id?.toString() &&
                !isEmployeeDisabled && (
                  <div
                    className="col-12 col-sm-12 p-0"
                    onClick={(e) => onEmployeeClick(e, profileData.career_manager.employee_id)}>
                    <div>
                      <p className="common-profile-title-text mb-2 mt-3">Career Manager</p>
                      <div className="common-profile-reporting-manager-container d-flex flex-row align-items-center justify-content-between mb-3 pl-3 pr-3">
                        <div className="d-flex flex-row align-items-center">
                          <UserProfilePhoto
                            imageBaseUrl={profileData.career_manager.profile_picture.base_url}
                            imagePath={profileData.career_manager.profile_picture.image_path}
                            imageClass="common-user-profile-photo-sm"
                            showBadge={true}
                            employeeId={profileData.career_manager.employee_id}
                          />
                          <div className="ml-3 mt-3">
                            <div className="d-flex align-items-center">
                              <p className="common-profile-supervisor-text mb-0 ">
                                {profileData.career_manager.first_name +
                                  ' ' +
                                  profileData.career_manager.last_name}
                              </p>
                              {!!profileData.career_manager.pronouns && (
                                <p className="common-profile-supervisor-pronounce-text mb-0 ml-1">
                                  {'(' + profileData.career_manager.pronouns + ')'}
                                </p>
                              )}
                            </div>
                            <p className="common-profile-supervisor-position-text">
                              {profileData.career_manager.position_title}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Divider style="common-profile-divider-style" />
                  </div>
                )}
              {!!profileData.display_name && (
                <div className="col-12 col-sm-12 p-0">
                  <div>
                    <p className="common-profile-title-text mb-2 mt-3">Nick Name</p>
                    <p className="common-profile-subtitle-text mb-3">{profileData.display_name}</p>
                  </div>
                  <Divider style="common-profile-divider-style" />
                </div>
              )}
              {(profileData.date_of_birth.visibility || isUserOwnProfile) &&
                !!getDOB(profileData.date_of_birth.date) && (
                  <div className="col-12 col-sm-12 p-0">
                    <div>
                      <p className="common-profile-title-text mb-2 mt-3">Birthday</p>
                      <div className="common-profile-row-container mb-3">
                        <p className="common-profile-subtitle-text mb-0">
                          {getDOB(profileData.date_of_birth.date)}
                        </p>
                        {isUserOwnProfile && (
                          <img
                            src={getFeatureComponentUrl(
                              accessibilityData,
                              profileData.date_of_birth.visibility
                                ? ACCESSIBILITY_FEATURE_COMPONENTS.PROFILE_VISIBLE
                                : ACCESSIBILITY_FEATURE_COMPONENTS.PROFILE_HIDE,
                              ACCESSIBILITY_IDENTIFIERS.PROFILE
                            )}
                            className="common-profile-visible-icon mr-3"
                          />
                        )}
                      </div>
                    </div>
                    <Divider style="common-profile-divider-style" />
                  </div>
                )}
              {(profileData.blood_group.visibility || isUserOwnProfile) &&
                !!profileData.blood_group.group && (
                  <div className="col-12 col-sm-12 p-0">
                    <div>
                      <p className="common-profile-title-text mb-2 mt-3">Blood Group</p>
                      <div className="common-profile-row-container mb-3">
                        <p className="common-profile-subtitle-text mb-0">
                          {profileData.blood_group.group}
                        </p>
                        {isUserOwnProfile && (
                          <img
                            src={getFeatureComponentUrl(
                              accessibilityData,
                              profileData.blood_group.visibility
                                ? ACCESSIBILITY_FEATURE_COMPONENTS.PROFILE_VISIBLE
                                : ACCESSIBILITY_FEATURE_COMPONENTS.PROFILE_HIDE,
                              ACCESSIBILITY_IDENTIFIERS.PROFILE
                            )}
                            className="common-profile-visible-icon mr-3"
                          />
                        )}
                      </div>
                    </div>
                    <Divider style="common-profile-divider-style" />
                  </div>
                )}
              {!!myVaccinationStatus && (
                <div className="col-12 col-sm-12 p-0">
                  <div>
                    <p className="common-profile-title-text mb-2 mt-3">
                      COVID-19 Vaccination Status
                    </p>
                    <div className="common-profile-row-container mb-3">
                      <p className="common-profile-subtitle-text mb-0">{myVaccinationStatus}</p>
                    </div>
                  </div>
                  <Divider style="common-profile-divider-style" />
                </div>
              )}
              {isUserOwnProfile && profileData?.permanent_address && (
                <div className="col-12 col-sm-12 p-0">
                  <div>
                    <p className="common-profile-title-text mb-2 mt-3">Permanent Address</p>
                    <p className="common-profile-subtitle-text mb-3">
                      {profileData?.permanent_address}
                    </p>
                  </div>
                  <Divider style="common-profile-divider-style" />
                </div>
              )}
              {isUserOwnProfile && profileData?.correspondence_address && (
                <div className="col-12 col-sm-12 p-0">
                  <div>
                    <p className="common-profile-title-text mb-2 mt-3">Correspondence Address</p>
                    <p className="common-profile-subtitle-text mb-3">
                      {profileData?.correspondence_address}
                    </p>
                  </div>
                  <Divider style="common-profile-divider-style" />
                </div>
              )}
              {isUserOwnProfile &&
                !!profileData.emergency_contact &&
                !!profileData.emergency_contact.name && (
                  <div className="col-12 col-sm-12 p-0">
                    <div>
                      <p className="common-profile-title-text mb-2 mt-3">Emergency Contact Name</p>
                      <p className="common-profile-subtitle-text mb-3">
                        {profileData.emergency_contact.name}
                      </p>
                    </div>
                    <Divider style="common-profile-divider-style" />
                  </div>
                )}
              {isUserOwnProfile &&
                !!profileData.emergency_contact.phone.isd_code &&
                !!profileData.emergency_contact.phone.number && (
                  <div className="col-12 col-sm-12 p-0">
                    <div>
                      <p className="common-profile-title-text mb-2 mt-3">Emergency Contact Phone</p>
                      <p className="common-profile-subtitle-text mb-3">
                        +
                        {profileData.emergency_contact.phone.isd_code +
                          ' ' +
                          profileData.emergency_contact.phone.number.slice(0, 5) +
                          ' ' +
                          profileData.emergency_contact.phone.number.slice(5, 10)}
                      </p>
                    </div>
                    <Divider style="common-profile-divider-style" />
                  </div>
                )}
              {isUserOwnProfile &&
                !!profileData.emergency_contact &&
                !!profileData.emergency_contact.email && (
                  <div className="col-12 col-sm-12 p-0">
                    <div>
                      <p className="common-profile-title-text mb-2 mt-3">Emergency Contact Email</p>
                      <p className="common-profile-subtitle-text mb-3">
                        {profileData.emergency_contact.email}
                      </p>
                    </div>
                    <Divider style="common-profile-divider-style" />
                  </div>
                )}
              {(!!profileData.social.linkedin_url ||
                !!profileData.social.twitter_url ||
                !!profileData.social.facebook_url ||
                !!profileData.social.instagram_url ||
                !!profileData.social.website_url) && (
                <div className="col-12 col-sm-12 p-0">
                  <div>
                    <p className="common-profile-title-text mb-2 mt-3">Social Connect</p>
                    <div className="d-flex mb-3">
                      {!!profileData.social.linkedin_url && (
                        <div
                          onClick={() => {
                            websiteRedirect(profileData.social.linkedin_url);
                          }}
                          className="common-cursor-pointer">
                          <img
                            src={getFeatureComponentUrl(
                              accessibilityData,
                              ACCESSIBILITY_FEATURE_COMPONENTS.DIRECTORY_LINKEDIN,
                              ACCESSIBILITY_IDENTIFIERS.DIRECTORY
                            )}
                            className="my-profile-social-media-icon mr-3"
                          />
                        </div>
                      )}
                      {!!profileData.social.twitter_url && (
                        <div
                          onClick={() => {
                            websiteRedirect(profileData.social.twitter_url);
                          }}
                          className="common-cursor-pointer">
                          <img
                            src={getFeatureComponentUrl(
                              accessibilityData,
                              ACCESSIBILITY_FEATURE_COMPONENTS.DIRECTORY_TWITTER,
                              ACCESSIBILITY_IDENTIFIERS.DIRECTORY
                            )}
                            className="my-profile-social-media-icon mr-3"
                          />
                        </div>
                      )}
                      {!!profileData.social.facebook_url && (
                        <div
                          onClick={() => {
                            websiteRedirect(profileData.social.facebook_url);
                          }}
                          className="common-cursor-pointer">
                          <img
                            src={getFeatureComponentUrl(
                              accessibilityData,
                              ACCESSIBILITY_FEATURE_COMPONENTS.ICON_URL,
                              ACCESSIBILITY_IDENTIFIERS.FACEBOOK
                            )}
                            className="my-profile-social-media-icon mr-3"
                          />
                        </div>
                      )}
                      {!!profileData.social.instagram_url && (
                        <div
                          onClick={() => {
                            websiteRedirect(profileData.social.instagram_url);
                          }}
                          className="common-cursor-pointer">
                          <img
                            src={getFeatureComponentUrl(
                              accessibilityData,
                              ACCESSIBILITY_FEATURE_COMPONENTS.ICON_URL,
                              ACCESSIBILITY_IDENTIFIERS.INSTAGRAM
                            )}
                            className="my-profile-social-media-icon mr-3"
                          />
                        </div>
                      )}
                      {!!profileData.social.website_url && (
                        <div
                          onClick={() => websiteRedirect(profileData.social.website_url)}
                          className="common-cursor-pointer">
                          <img
                            src={getFeatureComponentUrl(
                              accessibilityData,
                              ACCESSIBILITY_FEATURE_COMPONENTS.DIRECTORY_WEBSITE,
                              ACCESSIBILITY_IDENTIFIERS.DIRECTORY
                            )}
                            className="my-profile-social-media-icon mr-3"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <Divider style="common-profile-divider-style" />
                </div>
              )}
            </div>
          )}
          <div
            className="common-profile-show-more-text-container d-flex flex-row justify-content-center align-items-center mt-3 mb-3 common-cursor-pointer"
            onClick={() => {
              setShowMore(!showMore);
            }}>
            <div className="common-profile-show-more-text">
              {showMore ? 'Show Less' : 'Show More'}
            </div>
            <img
              src={getFeatureComponentUrl(
                accessibilityData,
                showMore
                  ? ACCESSIBILITY_FEATURE_COMPONENTS.PROFILE_SHOW_LESS
                  : ACCESSIBILITY_FEATURE_COMPONENTS.PROFILE_SHOW_MORE,
                ACCESSIBILITY_IDENTIFIERS.PROFILE
              )}
              className="common-profile-show-more-icon-size ml-1"
            />
          </div>
          {cheersData?.feeds?.length > 0 && (
            <div className="col-12 col-sm-12 p-0">
              <div>
                <div
                  className="d-flex flex-direction-row justify-content-between common-cursor-pointer align-items-center"
                  onClick={() => {
                    history.push(NAVIGATION_ROUTES.PROFILE_CHEERS_LISTING_SCREEN, {
                      empData: profileData,
                    });
                  }}>
                  <p className="common-profile-title-text mb-2 mt-3">Cheers Awards</p>
                  <div className="align-items-center">
                    <img
                      className="common-wish-card-right-icon mr-3"
                      src={getFeatureComponentUrl(
                        accessibilityData,
                        ACCESSIBILITY_FEATURE_COMPONENTS.DIRECTORY_RIGHT_ARROW,
                        ACCESSIBILITY_IDENTIFIERS.DIRECTORY
                      )}
                    />
                  </div>
                </div>
                <div className="d-flex flex-direction-row mb-3 flex-wrap">
                  {cheersData?.feeds?.map((item, index) => {
                    return (
                      <div
                        className="cheers-icon-text-view cheers-outer-view mr-2 mb-2 cheers-award-min-height mt-1 "
                        key={`${item?.cheer_employee_award_id}`}>
                        <img
                          src={getHeaderComponentUrl(
                            accessibilityData,
                            getAwardIcon(item.award_details.icon_name),
                            ACCESSIBILITY_IDENTIFIERS.CHEERS
                          )}
                          className="cheers-normal-icon"
                        />
                        <div className="ml-2 cheers-award-title-text">
                          {item?.award_details?.title ?? ''}
                        </div>
                      </div>
                    );
                  })}
                  {cheersData?.reward_count > 3 && (
                    <div
                      className="mb-2 mt-1 cheers-reward-count-remaining common-cursor-pointer"
                      onClick={() => {
                        history.push(NAVIGATION_ROUTES.PROFILE_CHEERS_LISTING_SCREEN, {
                          empData: profileData,
                        });
                      }}>
                      {`+${cheersData?.reward_count - 3}`}
                    </div>
                  )}
                </div>
              </div>
              <Divider style="common-profile-divider-style" />
            </div>
          )}

          {!isUserOwnProfile && profileData.enabled == 1 && (
            <div>
              {profileData.wishes.birthday && profileData?.date_of_birth?.visibility && (
                <div className="col-12 col-sm-12 p-0">
                  <WishCard
                    title={
                      isUserOwnProfile
                        ? WISHES_TITLES_SUBTITLES.SELF_TITLE_BIRTHDAY
                        : WISHES_TITLES_SUBTITLES.OTHER_TITLE_BIRTHDAY
                    }
                    onClick={() => {
                      const year = getCurrentYear();
                      history.push(`${NAVIGATION_ROUTES.CELEBRATION_WISHES}`, {
                        profileData,
                        celebrationDetails: { type: WISH_TYPES.BIRTHDAY },
                        year: getCurrentYear(),
                        fromRoute: NAVIGATION_ROUTES.EMPLOYEE_PROFILE_DETAIL,
                      });
                    }}
                    subTitle={
                      isUserOwnProfile
                        ? WISHES_TITLES_SUBTITLES.SELF_SUB_TITLE_BIRTHDAY
                        : `Send your wishes to ${profileData.first_name} ${profileData.last_name}`
                    }
                    wishType={WISH_TYPES.BIRTHDAY}
                  />
                </div>
              )}
              {profileData.wishes.anniversary && (
                <div className="col-12 col-sm-12 p-0">
                  <WishCard
                    title={
                      isUserOwnProfile
                        ? `Congratulations on completing ${profileData.service_years} ${
                            profileData.service_years === 1 ? 'year' : 'years'
                          } at Microland`
                        : WISHES_TITLES_SUBTITLES.OTHER_TITLE_ANNIVERSARY
                    }
                    onClick={() => {
                      const year = getCurrentYear();
                      history.push(`${NAVIGATION_ROUTES.CELEBRATION_WISHES}`, {
                        profileData,
                        celebrationDetails: { type: WISH_TYPES.ANNIVERSARY },
                        year: year,
                        fromRoute: NAVIGATION_ROUTES.EMPLOYEE_PROFILE_DETAIL,
                      });
                    }}
                    subTitle={
                      isUserOwnProfile
                        ? WISHES_TITLES_SUBTITLES.SELF_SUB_TITLE_ANNIVERSARY
                        : `${profileData.first_name} ${profileData.last_name} is celebrating ${
                            profileData.service_years
                          } ${
                            profileData.service_years === 1 ? 'year' : 'years'
                          } at Microland. Send your wishes.`
                    }
                    wishType={WISH_TYPES.ANNIVERSARY}
                  />
                </div>
              )}
              {profileData.wishes.advancement && (
                <div className="col-12 col-sm-12 p-0">
                  <WishCard
                    title={
                      isUserOwnProfile
                        ? WISHES_TITLES_SUBTITLES.SELF_TITLE_ADVANCEMENT
                        : WISHES_TITLES_SUBTITLES.OTHER_TITLE_ADVANCEMENT
                    }
                    onClick={() => {
                      const year = profileData.date_of_advancement.substring(0, 4);
                      history.push(`${NAVIGATION_ROUTES.CELEBRATION_WISHES}`, {
                        profileData,
                        celebrationDetails: { type: WISH_TYPES.ADVANCEMENT },
                        year: year,
                        fromRoute: NAVIGATION_ROUTES.EMPLOYEE_PROFILE_DETAIL,
                      });
                    }}
                    subTitle={
                      isUserOwnProfile
                        ? WISHES_TITLES_SUBTITLES.SELF_SUB_TITLE_ADVANCEMENT
                        : `Congratulate ${profileData.first_name} ${profileData.last_name}`
                    }
                    wishType={WISH_TYPES.ADVANCEMENT}
                  />
                </div>
              )}
              {profileData.wishes.new_joinees && (
                <div className="col-12 col-sm-12 p-0">
                  <WishCard
                    title={
                      isUserOwnProfile
                        ? WISHES_TITLES_SUBTITLES.SELF_TITLE_NEW_JOINEE
                        : WISHES_TITLES_SUBTITLES.OTHER_TITLE_NEW_JOINEE
                    }
                    onClick={() => {
                      const year = getCurrentYear();
                      history.push(`${NAVIGATION_ROUTES.CELEBRATION_WISHES}`, {
                        profileData,
                        celebrationDetails: { type: WISH_TYPES.NEW_JOINEE },
                        year: year,
                        fromRoute: NAVIGATION_ROUTES.EMPLOYEE_PROFILE_DETAIL,
                      });
                    }}
                    subTitle={
                      isUserOwnProfile
                        ? WISHES_TITLES_SUBTITLES.SELF_SUB_TITLE_NEW_JOINEE
                        : `Welcome ${profileData.first_name} ${profileData.last_name}`
                    }
                    wishType={WISH_TYPES.NEW_JOINEE}
                  />
                </div>
              )}
              {profileData.enabled == 1 && (
                <div className="col-12 col-sm-12 p-0">
                  <WishCard
                    title={
                      isUserOwnProfile
                        ? WISHES_TITLES_SUBTITLES.SELF_TITLE_NEW_JOINEE
                        : `${profileData.first_name} ${profileData.last_name}'s Celebrations`
                    }
                    onClick={() => {
                      history.push(`${NAVIGATION_ROUTES.ALL_WISHES}`, {
                        profileData,
                      });
                    }}
                    subTitle={
                      isUserOwnProfile
                        ? WISHES_TITLES_SUBTITLES.SELF_SUB_TITLE_NEW_JOINEE
                        : `View or send your wishes to ${profileData.first_name} ${profileData.last_name}`
                    }
                    wishType={WISH_TYPES.NEW_JOINEE}
                  />
                </div>
              )}
            </div>
          )}
          {!!profileData.som_links.length &&
            profileData.som_links.map((somEvent, index) => {
              return (
                <WishCard
                  key={index}
                  title={somEvent.title}
                  onClick={() => {
                    dispatch(resetAllTabs());
                    history.push({
                      pathname: `${NAVIGATION_ROUTES.SOM_MAIN}/${somEvent.som_id}`,
                      state: { visible_tabs: somEvent?.visible_tabs },
                    });
                  }}
                  somCard={true}
                  wishType={SCREEN_TITLE.SOM}
                />
              );
            })}
        </div>
      )}
    </div>
  );
};

export default EmployeeProfileDetailScreen;
