import React from 'react';
import { Nav } from 'react-bootstrap';

const LogoutBtn = () => {
  return (
    <Nav.Link target="_self" rel="noreferrer" href={`${process.env.REACT_APP_BASE_URL}/logout`}>
      <div className="header-logout-container">Log Out</div>
    </Nav.Link>
  );
};

export default LogoutBtn;
